@import '@/styles/imports/variables';

.card-container {
  display: flex;
  flex-direction: row;
  border: none;
  .card-img {
    padding: 4px;
    height: 120px;
    width: 180px;
  }
  .content-container {
    padding: 12px;

    h2 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'xs')) {
  .card-container {
  display: flex;
  flex-direction: row;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .card-img{
    height: 100%;
    width: 100%;
  }
  .content-container {
    padding: 10px;

    h2 {
      font-size: 16px;
    }
    p {
      font-size: 13px;
    }
  }
}
}

@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
  .card-container {
  display: flex;
  align-items: center;
  .card-img{
    height: 100%;
    width: 100%;
  }
  .content-container {
    padding: 10px;

    h2 {
      font-size: 16px;
    }
    p {
      font-size: 13px;
    }
  }
}
}

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .card-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  .card-img{
    height: 100%;
    width: 30%;
  }
  .content-container {
    padding: 12px;

    h2 {
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }
  }
}
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .card-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  .card-img{
    height: 100%;
    width: 30%;
  }
}
}
